<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Safety Passport</h2>
        <v-card outlined elevation="2">
            <v-row style="padding:10px;">
                <v-col cols="4" style="padding:10px;">
                    <v-img :src="profile" height="100px" ></v-img>
                </v-col>
                <v-col cols="8">
                    <p style="color: #002060; margin-bottom:0px !important;">{{ user_name }}</p>
                    <p style="color: #002060; margin-bottom:0px !important;"><strong>{{ user_position }}</strong></p>
                    <p style="color: #002060; margin-bottom:0px !important;">{{ user_id }}</p>
                </v-col>
            </v-row>
        </v-card>

        <!-- Medical certificate -->
        <v-card outlined elevation="2" class="mt-2 pa-3">
            <h3 style="color: #002060;">Medical Examination</h3>
            <p style="color: #002060; margin-bottom:0px !important;">Medical Status</p>
            <div v-if="medical">
                <v-btn color="success" style="text-transform: capitalize;" small class="mt-1">Fit to Work</v-btn>
                <p style="color: #002060; margin-top: 5px;margin-bottom:0px !important;"><strong>Last medical exam:</strong>{{ medical.date_certified }}</p>
                <a target="blank"  color="primary" :href="medicalUrl" style="text-transform: capitalize;" small class="my-anchor-tag mt-1">View Document</a> 
            </div>
            <v-btn v-else color="danger" class="mt-2">No Medical Record Found</v-btn>
        </v-card>       

        <!-- Permit to Work -->
        <v-card outlined elevation="2" class="mt-2 pa-3">
            <h3 style="color: #002060;">Permit To Work 
                <a v-if="permit" target="blank"  color="primary" :href="permitUrl"
                style="float: right;text-transform: capitalize;" small class="my-anchor-tag mt-1">View</a> 
            </h3>
            <div v-if="permit">
                {{ permit }}
                <p style="color: #002060; margin-bottom:0px !important;"><strong>Status: </strong><span style="text-transform: capitalize;">{{ permit.status }}</span></p>
                <p style="color: #002060; margin-bottom:0px !important;"><strong>Permit No: </strong>{{ permit.permit_no }}</p>
                <p style="color: #002060; margin-bottom:0px !important;"><strong>Approved Scope of Work:</strong></p>
                <p style="color: #002060; margin-bottom:0px !important;">{{ permit.tasks }}</p>
            </div>
            <v-btn v-else color="danger" class="mt-1">No Permit Found</v-btn>
        </v-card>

        <!-- Trainings -->
        <v-card outlined elevation="2" class="mt-2 pa-3">
            <h3 style="color: #002060;">Trainings 
                <v-btn @click="openTrainings()" v-if="expiredtrainings.length > 0 || trainings.length > 0" color="primary" style="float: right;text-transform: capitalize;" small class="my-anchor-tag mt-1">View</v-btn> 
            </h3>
            <p v-if="expiredtrainings.length > 0" style="color: #e90f0f; margin-bottom:0px !important;" >Trainings needed: {{ expiredtrainings.length }}</p>
            <p v-if="expiredtrainings.length == 0 && trainings.length > 0" style="color: #002060; margin-bottom:0px !important;">All Trainings ok! </p>
            <p v-if="expiredtrainings.length == 0 && trainings.length == 0" style="color: #002060; margin-bottom:0px !important;">No Training found </p>
        </v-card>

        <!-- Certifications -->
        <v-card outlined elevation="2" class="mt-2 pa-3">
            <h3 style="color: #002060;">Certifications 
                <v-btn @click="openCertificate()" v-if="certificates.length > 0" color="primary" style="float: right;text-transform: capitalize;" small class="my-anchor-tag mt-1">View</v-btn> 
            </h3>
            <p v-if="expiredcertificates.length > 0" style="color: #e90f0f; margin-bottom:0px !important;" >Certificates needed: {{ expiredcertificates.length }}</p>
            <p v-if="expiredcertificates.length == 0 && certificates.length > 0" style="color: #002060; margin-bottom:0px !important;">All Certificates ok! </p>
            <p v-if="expiredcertificates.length == 0 && certificates.length == 0" style="color: #002060; margin-bottom:0px !important;">No Certificate found </p>
        </v-card>

        <!-- Authorizations -->
        <v-card outlined elevation="2" class="mt-2 pa-3">
            <h3 style="color: #002060;">Authorizations 
                <v-btn @click="openAuthorization()" v-if="authorizations.length > 0" color="primary" style="float: right;text-transform: capitalize;" small class="my-anchor-tag mt-1">View</v-btn> 
            </h3>
            <p v-if="expiredauthorizations.length > 0" style="color: #e90f0f; margin-bottom:0px !important;" >Authorizations needed: {{ expiredauthorizations.length }}</p>
            <p v-if="expiredauthorizations.length == 0 && authorizations.length > 0" style="color: #002060; margin-bottom:0px !important;">All permissions ok! </p>
            <p v-if="expiredauthorizations.length == 0 && authorizations.length == 0" style="color: #002060; margin-bottom:0px !important;">Check permissions </p>
        </v-card>

        <!-- Restrictions -->
        <v-card outlined elevation="2" class="mt-2 pa-3 mb-5">
            <h3 style="color: #002060;">Restrictions 
                <v-btn @click="openRestriction()" v-if="restrictions.length > 0" color="primary" style="float: right;text-transform: capitalize;" small class="my-anchor-tag mt-1">View</v-btn> 
            </h3>
            <p v-if="expiredrestrictions.length > 0" style="color: #e90f0f; margin-bottom:0px !important;" >Restrictions needed: {{ expiredrestrictions.length }}</p>
            <p v-if="expiredrestrictions.length == 0 && restrictions.length > 0" style="color: #002060; margin-bottom:0px !important;">All restrictions ok! </p>
            <p v-if="expiredrestrictions.length == 0 && restrictions.length == 0" style="color: #002060; margin-bottom:0px !important;">No restriction found </p>
        </v-card>

    </v-container>
</template>

<script>

import Swal from 'sweetalert2'
export default {
    name:'SafetyPassport',
    data: () => ({
        profile:"https://camusatweb.ubuniworks.com/images/logo/camusat_plain_icon.png",
        user_name: "Loading ...",
        user_position: "",
        user_id: "",
        user:{},
        medical:null,
        permit:null,
        permitUrl: "",
        medicalUrl: "",
        trainings:[],
        expiredtrainings:[],
        certificates:[],
        expiredcertificates:[],
        restrictions:[],
        expiredrestrictions:[],
        authorizations:[],
        expiredauthorizations:[]
    }),
    methods:{
        openTrainings(){
            localStorage.setItem('trainings', JSON.stringify(this.trainings))
            this.$router.push('passportTraining')
        },
        openCertificate(){
            localStorage.setItem('certificates', JSON.stringify(this.certificates))
            this.$router.push('passportCertificate')
        },
        openAuthorization(){
            localStorage.setItem('authorizations', JSON.stringify(this.authorizations))
            this.$router.push('passportAuthorization')
        },
        openRestriction(){
            localStorage.setItem('restrictions', JSON.stringify(this.restrictions))
            this.$router.push('passportRestriction')
        },
        async pullPassport () {
            const requestOptions = { 
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    user: this.user.id,
                    type: this.user.type
                })
            };
            const res = await fetch('https://camusatweb.ubuniworks.com/api/v1/auth/passport', requestOptions)
            const data = await res.json()
            if(data.status == true){
                this.user_name = data.staff.name
                this.profile = data.staff.passport
                this.user_position = data.staff.position
                this.user_id = "Staff ID: "+data.staff.employee_id
                this.medical = data.medicals
                if(data.permit.length > 0){
                    this.permit = data.permit[0]
                    this.permitUrl = 'https://camusatweb.ubuniworks.com/documents/ptw/'+data.permit[0].id
                } else {
                    this.permit = null
                    this.permitUrl = ""
                }
                if(data.medicals){
                    this.medicalUrl = data.medicals.document
                } else {
                    this.medicalUrl = ""
                }
                this.trainings = data.trainings
                this.expiredtrainings = data.expiredtrainings
                this.certificates = data.certificates
                this.expiredcertificates = data.expiredcertificates
                this.restrictions = data.restrictions
                this.expiredrestrictions = data.expiredrestrictions
                this.authorizations = data.authorizations
                this.expiredauthorizations = data.expiredauthorizations
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Staff Account not found. Login again',
                    text: data.message
                })
                this.$router.push('/')
            }
        }
    },
    mounted () {
        this.user = JSON.parse(localStorage.getItem('user'))
        if(this.user == null){
            this.$router.push('/')
        }
        this.pullPassport()

    }

}
</script>

<style scoped>
.my-anchor-tag {
  display: inline-block;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #002060; /* Replace with the background color of v-btn */
}
</style>